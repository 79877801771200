import * as React from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router';
import { classNames } from 'web-shared/lib';
import { Section } from '..';
import { Tab, TabProps } from './tab';

export { Tab };
export * from './managedTabs';

const StyledDiv = styled.div`
    background: inherit;

    &.sticky { position: sticky; z-index: 99; }
    & .tabs ul { display: flex; margin-bottom: 0; flex-wrap: wrap; flex-shrink: 1; width: 100%; background: inherit; }
    & .spacer { width: 2px; height: 1.5em; background-color: #dbdbdb; margin: 0 1em; }
`;

interface Props {
    children: any;
    align?: 'left' | 'centered' | 'right';
    style?: 'boxed' | 'toggle' | 'toggle-rounded';
    fullWidth?: boolean;
    selectedTab?: number;
    buttons?: React.ReactElement<any>;
    onTabChange?: (tabIndex: number, props: TabProps) => void;
    sticky?: boolean | number;
    preTabContent?: React.ReactNode;
    mt?: string;
    mb?: string;
    tabsRef?: React.Ref<HTMLDivElement>;
}
export const Tabs: React.FC<Props> = (props) => {
    const history = useHistory();
    const tabChildren: React.ReactElement<TabProps>[] = React.Children.toArray(props.children);
    const defaultTab = tabChildren.findIndex(t => t.props.pathname === history.location.pathname);
    const [state, setState] = React.useState({
        selectedTab: defaultTab === -1 ? 0 : defaultTab,
    });

    const { children, buttons, selectedTab = state.selectedTab, align = 'left', style, fullWidth, sticky, tabsRef,
        onTabChange = (i: number) => {
            setState({ selectedTab: i });
            const tab = tabChildren[i];
            if(tab.props.pathname) {
                history.push(tab.props.pathname);
            }
        }
    } = props;

	// Set active and get the render function
	let tabFluid = false;
    let tabPaneRenderer: TabProps['render'] | undefined;
    const tabs = React.Children.map(children,
        (tab: React.ReactElement<TabProps>, index) => {
            if(index === selectedTab && !tab.props.hidden) {
				tabPaneRenderer = tab.props.render;
				tabFluid = tab.props.fluid === true;
            }

            if(!tab.props.name)
                return <div className="spacer"></div>;

            return <Tab key={index} {...tab.props} active={index === selectedTab} onClick={() => onTabChange && onTabChange(index, tab.props)} />;
        },
    );

    const isSticky = sticky !== undefined && sticky !== false;
    const stickyPos = sticky === true || !isSticky ? 0 : sticky as number;
    const cls = classNames('tabs', `is-${align}`, style ? `is-${style}` : '', { 'is-fullwidth': fullWidth });
    const rendered = tabPaneRenderer && tabPaneRenderer();

    const st: React.CSSProperties = {
        paddingTop: props.mt,
        paddingBottom: props.mb,
    };

    return <>
        <StyledDiv className={classNames({ 'sticky': isSticky })} ref={tabsRef} style={{ top: stickyPos, paddingBottom: '1em' }}>
            {props.preTabContent}
            <Section className="tabs" fluid style={st}>
                <div className={cls}>
                    <ul>
                        {tabs}
                        {buttons ? <li className="fill" /> : null}
                        {buttons}
                    </ul>
                </div>
            </Section>
        </StyledDiv>

        {rendered ? <Section style={{ paddingTop: 0, paddingBottom: props.mb }} fluid={tabFluid}>
            <div className="tab-content">
                {rendered}
            </div>
        </Section> : null}
    </>;
};
